import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  standalone: true,
  imports: [NgFor, NgIf],
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  public menuItems!: any[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.menuItems = this.createBreadcrumbs(this.activatedRoute.root);
      });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '#',
    breadcrumbs: any[] = []
  ): any {
    const children: ActivatedRoute[] = route.children.filter(
      (child: any) => child.snapshot.url.map((segment: any) => segment.path)[0]
    );
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string =
        child.snapshot.url.map((segment) => segment.path).join('/') ?? '';
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const breadcrumb =
        child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      const label =
        breadcrumb === undefined ? routeURL.replace(/-/g, ' ') : breadcrumb;

      if (label) {
        breadcrumbs.push({ label, url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
